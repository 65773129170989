import "@/shared/plugins/element";
import Vue from "vue";
import Vuex from "vuex";
import Router from "vue-router";
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from "@/app-module";
import app from "@/app.vue";
import ProgressBar from "@/shared/progress-bar/progress-bar";
import i18n from "./vueI18n";
import PortalVue from "portal-vue";
import "./quasar";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "../public/assets/sass/style.scss";
import Notifications from "vue-notification";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; //Vuesax styles
import VueTypedJs from "vue-typed-js";
import { Icon } from "@iconify/vue2";
import AOS from "aos";
import "aos/dist/aos.css";
import cImage from "@/shared/componants/c-image.vue";
Vue.use(VueTypedJs);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("iconify", Icon);
Vue.component("c-image", cImage);

Vue.use(Notifications);
Vue.use(Vuesax);

(async function() {
  ProgressBar.start();
  Vue.use(Router);
  Vue.use(PortalVue);
  Vue.config.productionTip = process.env.NODE_ENV === "production";
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();

  // eslint-disable-next-line
  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    render: (h) => h(app),
    mounted() {
      AOS.init();
    },
    i18n,
    components: {},
  }).$mount("#app");
})();
