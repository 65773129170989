//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductData from "@/shared/json/product-data.json";
import ProductDataAr from "@/shared/json/product-data-ar.json";
import i18n from "@/vueI18n";
import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  mounted() {
    this.scrollTop();
  },
  computed: {
    language() {
      return i18n.locale;
    },
    spareParts() {
      if (this.language == "en") return ProductData.spareParts;
      return ProductDataAr.spareParts;
    },
  },
  watch: {
    language() {
      document.title = document.title =
        this.i18n("app.title") + " | " + this.i18n("app.spareParts");
    },
  },
  methods: {
    viewSparePart(item) {
      this.$router.push(`/${this.language}/spare-parts/${item.id}`);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    scrollTop() {
      var offset = 0;
      const ele = document.getElementById("header");
      var target = getScrollTarget(ele);
      var duration = 0;
      this.drawer = false;
      setScrollPosition(target, offset, duration);
    },
  },
};
