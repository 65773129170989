import { render, staticRenderFns } from "./header.vue?vue&type=template&id=0c05a2c0&scoped=true&"
import script from "./header.vue?vue&type=script&lang=js&"
export * from "./header.vue?vue&type=script&lang=js&"
import style0 from "./header.vue?vue&type=style&index=0&id=0c05a2c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c05a2c0",
  null
  
)

export default component.exports
import {QScrollObserver,QImg,QDrawer,QBtn,QLayout} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QScrollObserver,QImg,QDrawer,QBtn,QLayout})
