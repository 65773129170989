import { i18n } from "@/vueI18n";
import SparePartViewPage from "@/modules/spare-part/components/spare-part-view-page.vue";
import SparePartListPage from "@/modules/spare-part/components/spare-part-list-page.vue";
const LayoutPage = () => import("@/modules/layout/components/layout.vue");

export default [
  {
    path: "",
    meta: { unauth: true },
    component: LayoutPage,
    children: [
      {
        name: "spare-parts/:id",
        path: "/:lang?/spare-parts/:id",
        component: SparePartViewPage,
        meta: { unauth: true },
        beforeEnter: (to, from, next) => {
          document.title = i18n("app.title") + " | " + i18n("app.spareParts");
          next();
        },
      },
      {
        name: "spare-parts",
        path: "/:lang?/spare-parts",
        component: SparePartListPage,
        meta: { unauth: true },
        beforeEnter: (to, from, next) => {
          document.title = i18n("app.title") + " | " + i18n("app.spareParts");
          next();
        },
      },
    ],
  },
];
