// import { localStorage, SessionStorage  } from 'quasar';

export default class QlocalStorage {
  /**
   * Set item in storage
   * @param key Entry key
   * @param value Entry value
   */
  static setItem(key, value) {
    // debugger;
    localStorage.setItem(key, value);
    // localStorage.set(key, value);
  }

  /**
   * Get a storage item value
   * @param key Entry key
   * @returns Storage item value
   */
  static getItem(key) {
    // debugger;
    return localStorage.getItem(key);
  }

  /**
   * Remove a storage item
   * @param key Storage key
   */
  static removeItem(key) {
    // return localStorage.remove(key);
    return localStorage.removeItem(key);
  }

  /**
   * Remove everything from the storage
   */
  static clear() {
    return localStorage.clear();
  }


  // static setSession(key, value){
  //   SessionStorage.set(key, value);
  // }

//#region (additional functions)
// /**
//  * Check if storage item exists
//  * @param key Entry key
//  * @returns Does the item exists or not?
//  */
// static has(key) {
//   return localStorage.has(key);
// }

// /**
//  * Get storage number of entries
//  * @returns Number of entries
//  */
// static getLength() {
//   return localStorage.getLength();
// }

// /**
//  * Get the storage item value at specific index
//  * @param index Entry index
//  * @returns Storage item index
//  */
// static getIndex(index) {
//   return localStorage.getIndex(index);
// }

// /**
//  * Retrieve all items in storage
//  * @returns Object syntax: item name as Object key and its value
//  */
// static getAll() {
//   return localStorage.getAll();
// }

// /**
//  * Determine if storage has any items
//  * @returns Tells if storage is empty or not
//  */
// static isEmpty() {
//   return localStorage.isEmpty();
// }
//#endregion

}
