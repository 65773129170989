//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { LocalStorage } from 'quasar';
import i18n from '@/vueI18n';
import { Quasar } from 'quasar'

export default {
  name: 'app-toggle-language',
  data: () => ({
    isArabic: true
  }),
  methods: {
    doChangeLanguage() {
      // console.log('LOCALE ', i18n.locale);
      var language;
      if (i18n.locale === 'en') {
        language = 'ar';
      } else {
        language = 'en';
      }

      i18n.locale = language;
      localStorage.setItem('language', language);
      const { lang } = this.$route.params;
      let route = '';
      if (lang && (lang === 'ar' || lang === 'en')) {
        route = `/${language}${this.$route.fullPath.substring(
          3,
        )}`;
      } else {
        route = `/${language}${this.$route.fullPath}`;
      }
      if (language === 'ar') {
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      } else {
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      this.$router.push(route);
      // console.log(this.isArabic);
    },
  },
  created(){
    this.isArabic = i18n.locale == 'ar' ? true : false
  }
};
