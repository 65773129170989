// import Vuex from 'vuex';
// import Vue from 'vue';

// Vue.use(Vuex)
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/firestore";
import { firestoreAction } from "vuexfire";

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    doSubmitContactForm: firestoreAction(({ rootGetters }, data) => {
      var id = firebase
        .firestore()
        .collection("ids")
        .doc().id;
      console.log(rootGetters["auth/currentUser"]);
      console.log(data);
      var obj = {
        id: id,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        message: data.message,
        product:data.product,
        createdAt: firebase.firestore.Timestamp.now(),
      };
      firebase
        .firestore()
        .collection("product-form")
        .doc(id)
        .set({
          ...obj,
        });

      if(data.language=='ar'){
        firebase.firestore().collection('mail').doc(id).set({
          to:data.email,
          template:{
            name: 'contactFormToClient-ar'
          }
         })
      }
      else{
        firebase.firestore().collection('mail').doc(id).set({
          to:data.email,
          template:{
            name: 'contactFormToClient-en'
          }
         })
      }

       id = firebase.firestore().collection('ids').doc().id
       firebase.firestore().collection('mail').doc(id).set({
        to:'info@esliegypt.com',
        template:{
          name: 'productContactFormToCompany',
          data:{
            name: data.name,
            phoneNumber: data.phoneNumber,
            email: data.email,
            message: data.message,
            product: data.product
          }
        }
       })
    }),
  },
};
