//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductData from "@/shared/json/product-data.json";
import ProductDataAr from "@/shared/json/product-data-ar.json";
import productCard from "@/modules/home/components/product-card.vue";
import i18n from "@/vueI18n";
import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
export default {
  mounted() {
    // this.scrollTop()
    // setTimeout(() => {
    //   this.loading = true
    // }, 500);
  },
  data() {
    return {
      loaders: new Array(5).fill(false),
      // loading: false,
    };
  },
  computed: {
    language() {
      return i18n.locale;
    },
    products() {
      if (this.language == "en") return ProductData.products;
      return ProductDataAr.products;
    },
    // loaders() {
    //   return new Array(this.products.length).fill(false)
    // },
    isLoaded() {
      return (index) => {
        const val = this.loaders[index];
        return val;
      };
    },
  },
  watch: {
    language() {
      document.title =
        this.i18n("app.title") + " | " + this.i18n("app.products");
    },
  },
  methods: {
    loaded(index) {
      console.log("loaded ", index);
      this.loaders[index] = true;
    },
    // isLoaded(index) {
    //   const val = this.loaders[index]
    //   debugger
    //   return val
    // },
    viewProduct(item) {
      this.$router.push(`/${this.language}/products/${item.id}`);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    scrollTop() {
      var offset = 0;
      const ele = document.getElementById("header");
      var target = getScrollTarget(ele);
      var duration = 0;
      this.drawer = false;
      setScrollPosition(target, offset, duration);
    },
  },
  components: {
    productCard,
  },
};
