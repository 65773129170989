//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
import feather from "feather-icons";
import I18nFlags from "../../../shared/i18n/i18n-flags.vue";
import i18n from "@/vueI18n";

export default {
  name: "app-header",
  components: {
    [I18nFlags.name]: I18nFlags,
  },
  data: () => ({
    drawer: false,
    view: {
      topOfPage: true,
    },
    itemsScroll: [
      // { title: "header.home", id: "home" },
      { title: "header.aboutUs", id: "about-us" },
      { title: "header.products", id: "products" },
      { title: "header.contactUs", id: "contact-us" },
    ],
    icon: "menu",
    closeIcon: "x",
  }),

  computed: {
    language() {
      return i18n.locale;
    },
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    async goToHome() {
      await this.$router.push("/");
    },
    async handleScroll(eleId) {
      if (
        this.$router.currentRoute.path.includes(`/products`) ||
        this.$router.currentRoute.path.includes(`/spare-parts`)
      ) {
        await this.goToHome();
        setTimeout(() => this.handleScroll(eleId), 500);
      }
      var offset;
      const ele = document.getElementById(eleId);
      var target = getScrollTarget(ele);
      var duration = 500;
      this.drawer = false;
      if (eleId == "home") {
        offset = 0;
      } else if (eleId == "contact-us") {
        offset = ele.offsetTop - 250;
      } else {
        offset = ele.offsetTop;
      }
      setScrollPosition(target, offset, duration);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },

    handleScroll2() {
      if (window.scrollY > 0) {
        if (this.view.topOfPage) {
          this.view.topOfPage = false;
        }
        console.log("top of page", window.scrollY);
      } else {
        if (!this.view.topOfPage) {
          this.view.topOfPage = true;
        }
        console.log("page2", window.scrollY);
      }
    },
  },
};
