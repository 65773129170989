//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from "@/vueI18n";
import ProductData from "@/shared/json/product-data.json";
import ProductDataAr from "@/shared/json/product-data-ar.json";
import sparePartContactForm from "./spare-part-contact-form.vue";
import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  mounted() {
    this.scrollTop();
  },
  components: { sparePartContactForm },
  computed: {
    language() {
      return i18n.locale;
    },
    items() {
      if (this.language == "en") return ProductData.spareParts;
      return ProductDataAr.spareParts;
    },
    item() {
      var newArray = this.items.filter((el) => {
        return el.id == this.$route.params.id;
      });
      return newArray[0];
    },
  },
  watch: {
    language() {
      document.title = document.title =
        this.i18n("app.title") + " | " + this.i18n("app.spareParts");
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    scrollTop() {
      var offset = 0;
      const ele = document.getElementById("header");
      var target = getScrollTarget(ele);
      var duration = 0;
      this.drawer = false;
      setScrollPosition(target, offset, duration);
    },
  },
};
