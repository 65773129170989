const ar = {
    common: {
      or: 'أو',
      action: 'العمليات',
      cancel: 'إلغاء',
      reset: 'إعادة تعيين',
      save: 'حفظ',
      apply: 'تطبيق',
      publish: 'نشر',
      submit: 'إرسال',
      search: 'بحث',
      edit: 'تعديل',
      remove: 'حذف',
      new: 'جديد',
      export: 'تصدير إلي أكسيل',
      noDataToExport: 'No data to export',
      import: 'استيراد',
      discard: 'تجاهل',
      yes: 'نعم',
      no: 'لا',
      pause: 'إيقاف',
      view: 'عرض',
      viewAndReply: 'عرض الشكوي والرد عليها',
      closedComplaint: 'إغلاق / إنهاء الشكوي',
      destroy: 'مسح',
      destroyAll: 'حذف الكل',
      mustSelectARow: 'يجب أختيار صف',
      mustSelectAStation: 'يجب إختيار محطة علي الأقل',
      mustSelectALine: 'من فضلك أختر مسار اولا',
      // mustSelectALineAndBus: 'Must select a line and bus',
      mustSelectALineAndBus:
      'من فضلك أختر مسار و حافلة اولا ثم أضف الرحلة',
      mustSelectAtLeastOneTrip: 'يجب إختيار رحلة علي الأقل',
      confirm: 'تأكيد',
      AM:'صباحا',
      PM:'مساءا',
      addUser: 'إضافة مستخدم',
      addOrganizer: 'إضافة مشرف',
      addBus: 'إضافة حافلة',
      addStation: 'إضافة محطة',
      addTiming: 'Add Timing',
      database: 'قاعدة البيانات ',
      chooseBus: 'أختر حافلة',
      chooseLine: 'أختر خط السير',
      next: 'التالي',
      history: 'سجل',
      viewClient: 'عرض العملاء',
      client: 'العملاء',
      activeBuses: 'الحافلات النشطة',
      activeTaftaf: 'طاف طاف نشط',
      send: 'إرسال',
      users: 'العملاء',
      date: 'التاريخ',
      time: 'الوقت',
      live: 'بث حى',
      searchAndAddAPin: '',
      add: 'إضافة',
      addStationsToCreateLine: 'أضف المحطات لإنشاء خطك',
      yourLine: 'الخط الخاص بك',
      addBusesToYourLine: 'إضافة الحافلات إلى خطك',
      busesList: 'قائمة الحافلات',
      hintNumberIsEn: 'يجب إدخال الأرقام باللغة الإنجليزية',
      ex: 'مثال',
      rePublish: 'إعادة النشر',
      mins: 'دقيقة',
      loading: ' جاري التحميل... ',
      searching: ' جاري البحث... ',
      to: 'إلي',
      uploadPicture: 'تحميل صورة',
      uploadVideo: 'تحميل فيديو',
      picture: 'صورة',
      video: 'فيديو',
      areYouSure: 'هل أنت متأكد؟',
      doYouWantToSaveThisChanges: 'هل تريد حفظ هذه التغييرات؟',
      doYouWantToDeleteThisPerson: 'هل تريد حذف هذا الشخص؟',
      doYouWantToDeleteThisVehicle: 'هل تريد حذف هذه السيارة؟',
      doYouWantToDeleteThisStation: 'هل تريد حذف هذه المحطة؟',
      doYouWantToDeleteThisTimeline: 'هل تريد حذف هذا التوقيت؟',
      doYouWantToDeleteThisLine: 'هل تريد حذف هذا الخط؟',
      doYouWantToDeleteThisAds: 'هل تريد حذف هذا الإعلان؟',
      doYouWantToDeleteThisPersons: 'هل تريد حذف هؤلاء الأشخاص؟',
      doYouWantToDeleteThisVehicles: 'هل تريد حذف هذه المركبات',
      doYouWantToDeleteThisStations: 'هل تريد حذف هذه المحطات؟',
      doYouWantToDeleteThisTimelines: 'هل تريد حذف هذة التوقيتات؟',
      doYouWantToDeleteThisLines: 'هل تريد حذف هذة الخطوط؟',
      doYouWantToDeleteThisAdss: 'هل تريد حذف هذة الإعلانات؟',
      yesDelete: 'نعم، أحذف',
      noThanks: 'لا، شكراً',
      oneOfRunprofCompanies:'احدى شركات ران بروف'
    },
  
    app: {
      title: 'موقع سايس',
    },
    
    layout:{
      about:'عنا',
      services:'الخدمات',
      contactUs:'تواصل معنا',
      becomeASayes:'كن سايس',
      downloadNow:'تحميل التطبيق'
    },
    about:{
      sayesApp:'تطبيق سايس!',
      parkItEasily:'اركنها بسهولة الان مع',
      parkItEasilyDescription:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري',
      getStarted:'ابدا الان',
      about:'عنا',
      weAre:'سايس',
      aboutDesc:'تطبيق سايس اسهل طريقة لركن لبسيارات فى مصر. يمكن للعملاء ايقاف سياراتهم الخاصة بسهولة باستخدام sayes.يمكنهم العثور على اقرب مراب بارخص الاسعار و افضل تصنيف و افضل طريقة مريحة',
      a:'هذه',
      collection:'مميزات ',
      ofFeatures:'التطبيق',
      garagesInMap:'الجراجات على الخريطة',
      garagesInMapDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .',
      payment:'طريقة الدفع',
      paymentDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .',
      notification:'الاشعارات',
      notificationDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .',
      allFunctional:'متضمنة كل ',
      featuresIncluded:'الخصائص الوظيفية',
      functionalFeaturesDesc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet .',
      learnMore:'اعرف المزيد',
      downloadTo:'حمل على',
      yourPhone:'جوالك',
      forty:'٤۰',
      screens:'شاشة'
    },
    services:{
      title1:'إركنها بسهولة الآن',
      title2:'مع تطبيق سايس',
      parkItEasily:'إركنها بسهولة',
      parkItEasilyDesc:'تطبيق سايس هو التطبيق الذي يمكنك من خلاله العثور على المكان المثالي لإيقاف مركبتك بأسهل الطرق وأسرعها',
      findingNearbyGarage:'العثور على جراج  قريب',
      findingNearbyGarageDesc:'تطبيق سايس يتيح لك اختيار اقرب جراج مع افضل سعر و تقييم',
      garagesInfo:'معلومات عن الجراجات',
      garagesInfoDesc:'يمكن أن يعرض لك تطبيق سايس معلومات كاملة عن كل جراج مثل اسمه والسعر تقييمه و صوره وغيرها من المعلومات التي يمكن أن تساعدك في اتخاذ قرارك ',
      navigating:'تحديد الواجهة',
      navigatingDesc:'يساعدك تطبيق سايس على الوصول إلى الجراج الذي اخترته عن طريق إنشاء أفضل طريق للوصول إلى وجهتك بأسرع وقت ممكن',
      trackingTime:'تتبع الوقت',
      trackingTimeDesc1:'يحسب لك التطبيق اقتباس تقريبي وقت وقوف المركبة',
      trackingTimeDesc2:`لذا من السهولة معرفة وقت وقوف المركبة و السعر التقريبي للوقوف`,
      paymentInfo:'معلومات عن الدفع',
      paymentInfoDesc:'يقوم تطبيق سايس بإنشاء فاتورة نهائية بتفاصيل كاملة حول وقت الدخول والخروج وتكلفة مواقف السيارات  ',
      visaOrCash:'نقداً او بالبطاقة الإئتمانية',
      visaOrCashDesc:'يتيح لك تطبيق سايس اختيار الطريقة المناسبة للدفع مقابل موقف السيارات الخاص بك باستخدام النقد أو بطاقة',
      one:'۱.',
      two:'۲.',
      three:'۳.',
      four:'٤.',
      five:'٥.',
      six:'٦.',
      seven:'٧.'
    },
    contactUs:{
      beInTouch:'كن على تواصل معنا',
      subtitle:'بالرجاء ملأ بياناتك بالأسفل',
      name:'الاسم',
      namePlaceholder:'ادخل اسمك',
      email:'البريد الالكترونى',
      emailPlaceholder:'ادخل بريدك الالكترونى',
      mobileNum:'رقم الجوال ',
      mobileNumPlaceholder:'ادخل رقم جوالك',
      notes:'ملاحظات ',
      notesPlaceholder:'طلابات خاصة ؟ اضفهم هنا'
    },
    downloadNow:{
      title:'!حمل تطبيقنا اليوم',
      subtitle:'سنساعدك على تحقيق اهدافك العملية و التسويقية',
      allRightReserved:'جميع الحقوق محفوظة © ٢٠٢١ سايس ذ.م.م '
    },
    becomeSayes:{
      becomeASayes:'كن سايس الآن',
      subtitle:'برجاء ادخال باقى البايانات ثم اضغط ارسال',
      name:'الاسم',
      namePlaceholder:'ادخل اسمك هنا',
      phone:'الهاتف',
      phonePlaceholder:'ادخل هاتفك هنا',
      address:'العنوان',
      addressPlaceholder:'ادخل عنوانك هنا',
      garageName:'اسم الجراج',
      garagePlaceholder:'ادخل اسم جراجك هنا',
      availableDate:'الميعاد المتاح',
      selectAvailableDate:'اختر الميعاد المتاح'
    },
    chat:{
      emailIsRequired:'Email is required',
      invalidEmailAddress:'Invalid Email Address',
      invalidPhoneNumber:'Invalid Phone Number'
    },
    auth :{
      signin:'تسجيل الدخول',
      signup:'انشاء حساب',
      enterEmailAndPassword:'ادخل بريدك الالكترونى او سجل الدخول عن طريق وسائل التواصل الاجتماعى',
      dontHaveAnAccount:`لا يوجد لديك حساب؟`,
      email:'بريد الكترونى',
      password:'كلمة المرور',
      loginWithFacebook:'تسجيل الدخول عن طريق فيسبوك',
      loginWithGoogle:'تسجيل الدخول عن طريق جوجل',
      alreadyHaveAnAccount:'هل لديك حساب؟',
      next:'الصفحة التالية',
      confirmPassword:'تاكيد كلمة المرور',
      name:'الاسم',
      oneOfTwo: "١|٢",
      twoOfTwo:"٢|٢",
      confirm: 'تأكيد',
      confirmYourEmail:'الرجاء تاكيد بريدك الالكترونى فى ',
      toContinue:'للاستكمال',
      resendEmailVerification:'اعادة ارسال بريد للتحقق'
    },
  
    entities: {
      // ==================================================== DASHBOARD ==================================================== //
      dashboard:{
        dashboard:'لوحة القيادة',
        database:'قاعدة البيانات',
        admins:'مشرفين',
        setting:'الإعدادات',


        realTimeData:'البيانات في الزمن الحقيقي',
        adminPanel:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري',
        garages:'جراجات',
        users:'مستخدمين',
        parkingTransaction:'معاملة وقوف السيارات',
        totalRevenue:'العلئد الكلى',
        sinceLastMonth:'منذ الشهر الماضى',
        carsAndMotorycles:'سيارات و موتوسيكلات',
        onlyCars:'سيارات فقط',
  
        id:'الرقم',
        name:'الاسم',
        photosId:'رقم الصورة',
        pricesId:'رقم السعر',
        ownerId:'رقم المالك',
        availableParking:'اماكن متاحة',
        description:'مواصفات',
        avatar:'الصورة الرمزية',
        firstName:'الاسم الاول',
        lastName:'الاسم الاخير',
        phoneNumber:'رقم الهاتف',
        garageId:'رقم الجراج',
        vehicleId:'رقم السيارة',
        clientId:'رقم العميل',
        promoCodeId:'رقم كود الخصم',
        organizerId:'رقم المنظم',
        collectorId:'رقم المحصل',
        parkingTransactionId:'رقم معاملات وقوف السيارات',
        authorizationId:'رقم الترخيص',
        paymentMethod:'طريقة الدفع',
        totalRate:'التقييم الكلى',
        isClient:'عميل؟',
        plateNumber:'رقم اللوحة',
        country:'البلد',
        status:'الحالة',
        emailAddress:'عنوان البريد الالكترونى',
        facebook:'فيسبوك',
        photoUrl:'رابط الصورة',
        hour:'ساعة',
        halfDay:'نصف يوم',
        fullDay:'يوم كامل',
        monthly:'شهرى',
        isEnable:'هل متاح',
        expiryDate:'تاريخ الصلاحية',
        code:'الكود',
        condition:'الشرط',
        sharedUrl:'الرابط المشترك',
        arriveTime:'وقت الوصول',
        exitTime:'وقت الرحيل',
        totalPayment:'الدفع الكلى',
        creditCardTransaction:'معاملات بطاقة الائتمان',
        indebtedness:'المديونية',
        isCollected:'هل تحصل؟',
        date:'التاريخ',
        role:'الدور',
        chats:'محادثات',
        searchForCollectors:'البحث عن محصلين',
        typeYourMessageHere:' اكتب رسالتك هنا...',
        web:'الويب',
        collector:'محصل',
        manager:'مدير',
        uploadYourAvatar:'قم بتحميل الصورة الرمزية الخاصة بك',
        save:'سجل',
        reset:'اعدة ضبط',
        email:'بريد الكترونى',
        location:'موقع',
        availableParkingArea:'اماكن وقوف متاحة',
        organizers:'منظمين',
        parkingAreaAvailableInThisGarage:'اماكن وقوف متاحة فى هذا الجراج',
        viewMore:'عرض المزيد',
        approve:"موافق",
        reject:'ارفض',
        rejected:'تم رفضه',
        rejectionReason:'من فضلك اخبرنا سبب الرفض',
        typeYourReasonHere:'اكتب السبب هنا',
        prices:'اسعار',
        createdAt:'أنشئت في',
        garageType:'نوع الجراج',
        aboutThisGarage:'معلومات عن هذا الجراج',
        viewLocationOnMap:'عرض على الخريطة',
        request:'طلب',
        rejectReason:'سبب الرفض',
        activity:'النشاط',
        totalGarageRevenue:'عائد الجراج اللى',
        lastMonth:'اخر شهر',
        lastThreeMonth:"اخر ٣ شهور",
        accountCreatedAt:'حسابك تم انشائه فى ',
        noParking:'لا يوجد اماكن متاحة لوقوف فى هذا الجراج',
        saveChanges:'حفظ المتغيرات',
        logout:'خروج',
        signout:'هل تريد الخروج',
        yes:'نعم',
        noThanks:'لا,شكرا',

        owners:'المالكين',
        clients:'العملاء',
        photos:'الصور',
        rate:'التقييم',
        problems:'الشكاوى',
        vehicles:'السيارات',
        payment:'الدفع',
        invitation:'الدعاوى',
        promoCode:'اكواد الخصم',
        parkingHistory:'تاريخ وقوف السيارات',
        favourite:'المفضلات',
        garagesIndebtedness:'مديونية الجراج',
        thisGarageIsFor:'هذا الجراج مخصص لل ',
        adminForm:'نموذج المشرف',
        garage:'جراج',
        garageRequest:'طلب جراج',
        garageRequests:'طلبات الجراجات',
  
  
        garagesTable:'جدول الجراجات',
        garagesHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        garageOwnersTable:'جدول مالكى الجراجات',
        garageOwnersHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',

        organizersTable:'جدول المنظمين',
        organizersHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        clientsTable:'جدول العملاء',
        clientsHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        photosTable:'جدول الصور',
        photosHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        

        pricesTable:'جدول الاسعار',
        pricesHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        rateTable:'جدول التقييم',
        rateHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        complaintsTable:'جدول الشكاوى',
        complaintsHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        vehicleTable:'جدول السيارات',
        vehicleHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        paymentTable:'جدول الدفع',
        paymentHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        promoCodeTable:'جدول اكواد الخصم',
        promoCodeHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        invitationTable:'جدول الدعوات',
        invitationHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        parkingTransactionTable:'جدول معاملة وقوف السيارات ',
        parkingTransactionHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        parkingHistoryTable:'جدول تاريخ وقوف السيارات',
        parkingHistoryHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        favouriteTable:'جدول المفضلات',
        favouriteHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        garageindebtednessTable:'جدول مديونية الجرجات',
        garageindebtednessHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        adminHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول ,',
        addNewAdmin:'اضافة مشرف جديد',

        settingHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        pendingRequests:'جدول طلبات قيد الانتظار',
        pendingRequestsHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        notifications:'اشعارات',
        notificationHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
        
        profile:'الملف الشخصي',
        profileHeader:'كل أفاق المحيط واقتصار أن. يبق عن انتهت أجزاء, تصرّف العدّ التقليدي ذلك و. كلّ قد فكان أحدث الإطلاق, ألمّ العسكري دول عل. هو المنتصر انتصارهم اليابانية ومن, من بحق الدول بمعارضة, عرفها الأهداف البشريةً ان يبق.',
    },
      // ==================================================== PERSON ==================================================== //
      person: {
        name: 'person',
        label: 'Users',
        menu: {
          organizer: 'المشرفين',
          client: 'العملاء',
        },
        exporterFileName: 'person_export',
        list: {
          menu: 'Users',
          title: 'Users',
          client: 'العملاء',
          organizer: 'المشرفين',
        },
        create: {
          success: 'تم حفظ المشرف بنجاح',
        },
        update: {
          success: 'تم تحديث بيانات المشرف بنجاح',
        },
        destroy: {
          success: 'تم حذف المشرف بنجاح',
        },
        destroyAll: {
          success: 'تم حذف المشرفين المختارة بنجاح',
        },
        edit: {
          title: 'تعديل المشرف',
        },
        fields: {
          id: 'رقم المستخدم',
          email: 'البريد الإكتروني',
          // profilePicture: 'Profile Picture',
          profilePicture: 'الصورة الشخصية',
          phoneNumberRange: 'رقم الهاتف',
          phoneNumber: 'رقم الهاتف',
          personTypeIdRange: 'نوع المستخدم',
          personTypeId: 'نوع المستخدم',
          pIdRange: 'PId',
          pId: 'Person Id',
          authenticationUid: 'Authentication Uid',
          fullName: 'الإسم بالكامل',
          firstName: 'الإسم الأول',
          lastName: 'الإسم الأخير',
          available: 'متاح',
          disabled: 'محظور',
          busId: 'تفاصيل الحافلة',
          deviceToken: 'Device Token',
  
          // === not found in database === //
          region: 'المنطقة',
          vehicleType: 'نوع الحافلة',
          availability: 'السائق',
          status: 'الحالة',
          // ============================= //
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        available: 'متاح الان',
        unavailable: 'غير متاح الان',
        enumerators: {
          admin: 'مدير',
          organizer: 'مشرف',
          client: 'عميل',
        },
        new: {
          title: 'إضافة مشرف',
        },
        view: {
          title: 'عرض',
        },
        importer: {
          title: 'إستيراد',
          fileName: 'person_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
        errors: {
          phoneNumberAlreadyExists:
            'رقم الهاتف موجود بالفعل لمستخدم آخر',
        },
      },
      // ==================================================== BUS ==================================================== //
      bus: {
        name: 'حافلة',
        label: 'الحافلات',
        menu: 'الحافلات',
        active: 'نشط',
        inActive: 'غير نشط',
        insideCity: 'داخل إسكندرية',
        outsideCity: 'خارج إسكندرية',
        status: 'الحالة',
        place: 'المكان',
  
        exporterFileName: 'Vehicle_export',
        list: {
          menu: 'الحافلات',
          title: 'الحافلات',
        },
        create: {
          success: 'تم إضافة الحافلة بنجاح',
        },
        update: {
          success: 'تم تحديث الحافلة بنجاح',
        },
        destroy: {
          success: 'تم حذف الحافلة بنجاح',
        },
        destroyAll: {
          success: 'تم حذف الحافلات المختارة بنجاح',
        },
        edit: {
          title: 'تعديل الحافلة ',
        },
        fields: {
          id: 'رقم الحافلة',
          status: 'الحالة',
          place: 'المكان',
          bIdRange: 'BId',
          isOutsideAlex: 'خارج إسكندرية',
          vehicleNumberRange: 'رقم الحافلة',
          vehicleNumber: 'رقم الحافلة',
          vehiclePlate: 'لوحة المركبة',
          vehicleType: 'نوع الحافلة',
          seatsNumberRange: 'عدد المقاعد',
          seatsNumber: 'عدد المقاعد',
          availableSeats: 'المقاعد المتوفرة',
          lng: 'خط الطول',
          lat: 'خط العرض',
          available: 'متاح',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        enumerators: {
          bus: 'حافلة',
          taftaf: 'طاف طاف',
          bicycle: 'دراجة',
        },
        new: {
          title: 'حافلة جديدة',
        },
        view: {
          title: 'عرض الحافلة',
        },
        importer: {
          title: 'إستيراد',
          fileName: 'Vehicle_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== LINE ==================================================== //
      line: {
        name: 'خط السير',
        label: 'خطوط السير',
        menu: 'خطوط السير',
        exporterFileName: 'line_export',
        list: {
          menu: 'خطوط السير',
          title: 'خطوط السير',
        },
        create: {
          success: 'تم حفظ خط السير بنجاح',
        },
        update: {
          success: 'تم تحديث خط السير بنجاح',
        },
        destroy: {
          success: 'تم حذف خط السير بنجاح',
        },
        destroyAll: {
          success: 'تم حذف خطوط السير المختارة بنجاح',
        },
        edit: {
          title: 'تعديل خط السير',
        },
        fields: {
          id: 'الرقم التعريفى',
          name: 'الإسم',
          direction: 'الإتجاه',
          station: 'المحطات',
          buses: 'الحافلات',
  
          stationId: 'رقم المحطة',
          stationName: 'إسم المحطة',
          arranement: 'Arranement',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        enumerators: {},
        new: {
          add: 'إضافة خط جديد',
          title: 'إنشاء خط سير جديد',
        },
        view: {
          title: 'عرض خط السير',
        },
        importer: {
          title: 'إستيراد ',
          fileName: 'Line_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== CLIENT TRIP ==================================================== //
      clientTrip: {
        name: 'رحلات العميل',
        label: 'رحلات العملاء',
        menu: 'رحلات العملاء',
        exporterFileName: 'Trip_export',
        list: {
          menu: 'رحلات العملاء',
          title: 'رحلات العملاء',
        },
        create: {
          success: 'Trip saved successfully',
        },
        update: {
          success: 'Trip saved successfully',
        },
        destroy: {
          success: 'Trip deleted successfully',
        },
        destroyAll: {
          success: 'Trip(s) deleted successfully',
        },
        edit: {
          title: 'Edit Trip',
        },
        fields: {
          id: 'كود الرحلة',
          busId: 'رقم الحافلة',
          userId: 'كود العميل',
          lineId: '',
          tripId: '',
          tripTimingId: '',
          status: 'الحالة',
          stationId: 'كود المحطة',
          rideStation: 'محطة الركوب',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
  
          clientName: 'إسم العميل',
          clientPhone: 'رقم العميل',
          lineName: 'المسار',
          vehicleNumber: 'رقم الحافلة',
          vehiclePlate: 'Vehicle Plate',
          vehicleType: 'نوع الحافلة',
          isOutsideAlex: 'isOutsideAlex',
          startTime: 'Start Time',
          endTime: 'End Time',
          date: 'تاريخ الرحلة',
          time: 'وقت الرحلة',
        },
        enumerators: {
          pending: 'معلقة',
          canceled: 'تم الإلغاء',
          entered: 'تم الركوب',
          finished: 'تم الإنتهاء',
          done: 'تمت',
        },
        new: {
          title: 'Create New Trip',
        },
        view: {
          title: 'View Trip Transaction',
        },
        importer: {
          title: 'Import Trip',
          fileName: 'Trip_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== BUS TRIP ==================================================== //
      trip: {
        name: 'الرحلة',
        label: 'Bus to Line',
        menu: 'الرحلات',
        exporterFileName: 'Trip_export',
        list: {
          menu: 'الرحلات',
          title: 'الرحلات',
          view: 'عرض الرحلات',
        },
        create: {
          success: 'تم إضافة الرحلة بنجاح',
        },
        update: {
          success: 'تم تحديث الرحلة بنجاح',
        },
        updateEnabled: {
          enabled: 'تم تمكين الرحلة بنجاح',
          disabled: 'تم تعطيل الرحلة بنجاح',
        },
        destroy: {
          success: 'تم حذف الرحلة بنجاح',
        },
        destroyAll: {
          success: 'تم حذف الرحلات المختارة بنجاح',
        },
        edit: {
          title: 'تعديل الرحلة',
        },
        fields: {
          id: 'كود الرحلة',
          busId: 'كود الحافلة',
          lineId: 'خط السير',
          activeTimeline: 'Active Timeline',
  
          // ============= Not Found In Database ============= //
          endTime: 'إنتهاء الرحلة',
          startTime: 'بداية الرحلة',
          timeline: 'الفترة الزمنية',
          station: 'المحطات',
          waitingTime: 'وقت الانتظار',
          arrivelTime: 'وقت الوصول',
          lineName: 'خط السير',
          lineDirection: 'إتجاه خط السير',
          busNumber: 'رقم الحافلة',
          busPlate: 'لوحة المركبة',
          from: 'من',
          to: 'إلي',
          // ================================================= //
  
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        enumerators: {},
        new: {
          title: 'إضافة رحلة جديدة',
          button: 'إضافة رحلات',
        },
        view: {
          title: 'عرض الرحلة',
        },
        importer: {
          title: 'إستيراد',
          fileName: 'Trip_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== BUS TRIP ==================================================== //
      busTrip: {
        name: 'BusTrip',
        label: 'Bus to Line',
        menu: 'Bus to Line',
        exporterFileName: 'busTrip_export',
        list: {
          menu: 'Bus Trips',
          title: 'Bus Trips',
        },
        create: {
          success: 'Successfully added bus trip',
        },
        update: {
          success: 'Trip saved successfully',
        },
        destroy: {
          success: 'Trip deleted successfully',
        },
        destroyAll: {
          success: 'Trip(s) deleted successfully',
        },
        edit: {
          title: 'Edit ',
        },
        fields: {
          id: 'الرقم التعريفى',
          busId: 'Bus',
          lineId: 'Line',
          tripDetails: 'Trip Details',
          startTime: 'Start Time',
          endTime: 'End Time',
          // 'stationId': 'Station Id',
          // 'stationName': 'Station Name',
          // 'arranement': 'Arranement',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        enumerators: {},
        new: {
          title: 'Add New Trip',
          button: 'Add Trip',
        },
        view: {
          title: 'View Trip',
        },
        importer: {
          title: 'Import bus trip',
          fileName: 'BusTrip_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== STATION ==================================================== //
      station: {
        name: 'المحطة',
        label: 'المحطات',
        menu: 'المحطات',
        exporterFileName: 'station_export',
        list: {
          menu: 'المحطات',
          title: 'المحطات',
        },
        create: {
          success: 'تم حفظ المحطة بنجاح',
        },
        update: {
          success: 'تم تحديث المحطة بنجاح',
        },
        destroy: {
          success: 'تم حذف المحطة بنجاح',
        },
        destroyAll: {
          success: 'تم حذف المحطات المختارة بنجاح',
        },
        edit: {
          title: 'تعديل المحطة',
        },
        fields: {
          id: 'الرقم التعريفى',
          name: 'إسم المحطة',
          lng: 'خط الطول',
          lat: 'خط العرض',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        enumerators: {},
        new: {
          title: 'إنشاء محطة جديدة',
        },
        view: {
          title: 'عرض المحطة',
        },
        importer: {
          title: 'إستيراد',
          fileName: 'station_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== BUS HISTORY ==================================================== //
      busHistory: {
        name: 'busHistory',
        label: 'Buses History',
        menu: 'Buses History',
        exporterFileName: 'busHistory_export',
        list: {
          menu: 'Buses History',
          title: 'Buses History',
        },
        create: {
          success: 'Bus History saved successfully',
        },
        update: {
          success: 'Bus History saved successfully',
        },
        destroy: {
          success: 'Bus History deleted successfully',
        },
        destroyAll: {
          success: 'Bus History(s) deleted successfully',
        },
        edit: {
          title: 'Edit Bus History',
        },
        fields: {
          id: 'الرقم التعريفى',
          busId: ' الرقم التعريفى للحافلة',
          lineId: 'الرقم التعريفى لخط السير',
          organizerId: 'Organizer الرقم التعريفى',
          lineDirection: 'Line',
          organizerName: 'Organizer Name',
          date: 'Date',
          time: 'Time',
          line: 'Line',
          client: 'Clients',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        enumerators: {},
        new: {
          title: 'New Bus History',
        },
        view: {
          title: 'View Bus History',
        },
        importer: {
          title: 'Import Buses History',
          fileName: 'busHistory_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== ADVERTISMENT ==================================================== //
      advertisment: {
        name: 'الإعلانات',
        label: 'الإعلانات',
        menu: 'الإعلانات',
        exporterFileName: 'Advertisement_export',
        list: {
          menu: 'الإعلانات',
          title: 'الإعلانات',
        },
        create: {
          success: 'تم حفظ الإعلان بنجاح',
        },
        update: {
          success: 'تم تحديث الإعلان بنجاح',
        },
        rePublish: {
          success: 'تم إعادة نشر الإعلان بنجاح',
        },
        destroy: {
          success: 'تم حذف الإعلان بنجاح',
        },
        destroyAll: {
          success: 'تم حذف الإعلانات المختارة بنجاح',
        },
        edit: {
          title: 'تعديل الإعلان',
        },
        fields: {
          id: 'الرقم التعريفى',
          avatars: 'الإعلان',
          videos: 'فيديو',
          publishDate: 'تاريخ النشر',
          expirationDate: 'تاريخ الإنتهاء',
          adsType: 'نوع الإعلان',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        adsTypeEnum: {
          image: 'صورة',
          video: 'فيديو',
        },
        enumerators: {},
        new: {
          title: 'إضافة إعلان جديد',
        },
        view: {
          title: 'عرض تفاصيل الإعلان',
        },
        importer: {
          title: 'إستيراد',
          fileName: 'Advertisment_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== PROMO CODE ==================================================== //
      promoCode: {
        name: 'رمز ترويجي',
        label: 'رمز ترويجي',
        menu: 'رمز ترويجي',
        exporterFileName: 'PromoCode_export',
        list: {
          menu: 'رمز ترويجي',
          title: 'رمز ترويجي',
        },
        create: {
          success: 'تم حفظ الرمز ترويجي بنجاح',
        },
        update: {
          success: 'تم تحديث الرمز ترويجي بنجاح',
        },
        destroy: {
          success: 'تم حذف البرومو الكود بنجاح',
        },
        destroyAll: {
          success: 'Promo Code(s) deleted successfully',
        },
        edit: {
          title: 'تعديل',
        },
        fields: {
          id: 'الرقم التعريفى',
          name: 'الإسم',
          numberOfPoints: 'عدد النقاط',
          numberOfTrips: 'عدد الرحلات',
          percentageOfTrips: 'نسبة الخصم',
          expirationDate: 'تاريخ الإنتهاء',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        enumerators: {},
        new: {
          title: 'إضافة رمز ترويجي',
        },
        view: {
          title: 'عرض تفاصيل الرمز ترويجي',
        },
        importer: {
          title: 'إستيراد',
          fileName: 'Promo_Code_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ================================================== REWARDING POINTS ================================================ //
      rewarding: {
        name: 'نقاط المكافئة',
        label: 'نقاط المكافئة',
        menu: 'نقاط المكافئة',
        exporterFileName: 'Rewarding_export',
        list: {
          menu: 'نقاط المكافئة',
          title: 'نقاط المكافئة',
        },
        create: {
          success: 'تم حفظ البيانات بنجاح',
        },
        update: {
          success: 'تم تحديث البيانات بنجاح',
        },
        destroy: {
          success: 'Rewarding Points deleted successfully',
        },
        destroyAll: {
          success: 'Rewarding Point(s) deleted successfully',
        },
        edit: {
          title: 'Edit Rewarding Points',
        },
        fields: {
          id: 'الرقم التعريفى',
          available: 'متاح',
          numberOfPointsPerTrip: 'عدد النقاط لكل رحلة',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        userRewardFields: {
          id: 'الرقم التعريفى',
          name: 'العميل',
          numberOfRides: 'عدد الرحلات',
          rewardingPoints: 'نقاط المكافئة',
        },
        enumerators: {},
        new: {
          title: 'Add New Rewarding Points',
        },
        view: {
          title: 'View Rewarding Points',
        },
        importer: {
          title: 'Import Rewarding Points',
          fileName: 'Rewarding_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
  
      // ================================================== NOTIFICATIONS ================================================ //
      notification: {
        name: 'الإشعارات',
        label: 'الإشعارات',
        menu: 'الإشعارات',
        exporterFileName: 'Notification_export',
        list: {
          menu: 'الإشعارات',
          title: 'الإشعارات',
        },
        Send: {
          success: 'تم إرسال الإشعار بنجاح',
        },
        create: {
          success: 'تم حفظ الإشعار بنجاح',
        },
        update: {
          success: 'تم تحديث الإشعار بنجاح',
        },
        destroy: {
          success: 'تم حذف الإشعار بنجاح',
        },
        destroyAll: {
          success: 'تم حذف جميع الإشعارات المختارة بنجاح',
        },
        edit: {
          title: 'تعديل الإشعار',
        },
        fields: {
          id: 'الرقم التعريفى',
          title: 'العنوان',
          body: 'نص الرسالة',
          image: 'صورة الإشعار',
          name: 'المسمي',
          platform: 'المنصة',
          status: 'الحالة',
          sendDate: 'بدء / أرسل',
          endDate: 'النهاية',
          typeOfSender: 'نوع المرسل إلية',
          sendTo: 'إرسال هذا الإشعار إلي ',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        placeholder: {
          notificationTitle: 'أدخل عنوان الإشعار',
          notificationText: 'أدخل نص الإشعار',
          notificationName: 'أدخل مسمي (إختيارياً)',
        },
        enumerators: {
          clientApp: 'تطبيق العميل',
          organizerApp: 'تطبيق المشرف',
        },
        new: {
          title: 'إضافة إشعار جديد',
        },
        view: {
          title: 'تفاصيل الإشعار',
        },
        importer: {
          title: 'إستيراد',
          fileName: 'Notification_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== TIMELINE ==================================================== //
      timeline: {
        name: 'الجدول الزمني',
        label: 'الجدول الزمني',
        menu: 'الجدول الزمني',
        exporterFileName: 'Timeline_export',
        list: {
          menu: 'الجدول الزمني',
          title: 'الجدول الزمني',
        },
        create: {
          success: 'تم إضافة فترة زمنية بنجاح',
        },
        update: {
          success: 'تم تحديث الفترة الزمنية بنجاح',
        },
        destroy: {
          success: 'تم حذف الفترة الزمنية بنجاح',
        },
        destroyAll: {
          success: 'Timeline(s) deleted successfully',
        },
        edit: {
          title: 'تعديل الفترة الزمنية',
        },
        fields: {
          id: 'الرقم التعريفى',
          name: 'المسمي',
          startDate: 'تاريخ البداية ',
          endDate: 'تاريخ النهاية',
  
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'إنشاء في',
        },
        enumerators: {},
        new: {
          title: 'إضافة فترة زمنية',
        },
        view: {
          title: 'عرض الفاصيل',
        },
        importer: {
          title: 'إستيراد',
          fileName: 'Timeline_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space ',
        },
      },
      // ==================================================== COMPLAINTS ==================================================== //
      complaint: {
        complaint: 'الشكوى',
        name: 'الشكاوى و المقترحات',
        label: 'الشكاوى و المقترحات',
        menu: 'الشكاوى و المقترحات',
        exporterFileName: 'complaint_export',
        alertMessage:
          'تم إغلاق هذه الشكوى و لا يمكن فتحها مرة أخرى',
        list: {
          menu: 'الشكاوى و المقترحات',
          title: 'الشكاوى و المقترحات',
        },
        reply: {
          showPrevious: 'إظهار الردود السابق',
          previous: 'الردود السابقة',
          placeholder: 'اكتب رسالتك هنا',
          text: 'الرد',
          title: 'رد',
          success: 'تم حفظ الرد بنجاح',
        },
        create: {
          success: 'Complaint saved successfully',
        },
        update: {
          success: 'Complaint updated successfully',
        },
        destroy: {
          success: 'Complaint deleted successfully',
        },
        destroyAll: {
          success: 'Complaint(s) deleted successfully',
        },
        edit: {
          title: 'Edit Complaint',
        },
        fields: {
          id: 'كود الشكوى',
          complaintNumber: 'رقم الشكوى',
          complaintDescription: 'الشكوى',
          clientPhone: 'هاتف العميل',
          clientName: 'إسم العميل',
          email: 'البريد الإلكتروني',
          busId: 'كود الحافلة',
          tripId: 'كود الرحلة',
          busNumber: 'رقم الحافلة',
          tripNumber: 'رقم الرحلة',
          status: 'حالة الشكوى',
          reply: 'الردود',
          createdAt: 'إنشاء في',
          updatedAt: 'تعديل في',
          createdAtRange: 'Created at',
        },
        enumerators: {
          closed: 'مغلقة',
          pending: 'معلقة',
          opened: 'مفتوحة',
        },
        new: {
          title: 'Add New Complaint',
        },
        view: {
          title: 'View Complaint',
        },
        importer: {
          title: 'Import Complaint',
          fileName: 'Complaint_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space.',
        },
      },
    },
  
    // auth: {
    //   splash: {
    //     title: 'مرحباً بك فى سموحة باص',
    //     subTitle: 'لوحة التحكم',
    //   },
    //   profile: {
    //     title: 'تعديل الملف الشخصي',
    //     success: 'تم تحديث الملف الشخصي بنجاح',
    //   },
    //   createAnAccount: 'إنشاء حساب جديد ؟',
    //   rememberMe: 'تذكرني',
    //   forgotPassword: {
    //     label: 'هل نسيت كلمة المرور؟',
    //     title: 'نسيت كلمة المرور؟',
    //     message: `أدخل عنوان بريدك الإلكتروني الذي تستخدمه لحسابك ، وسنرسل لك رابطًا لإعادة تعيين كلمة المرور`,
    //     emailAddress: 'أدخل عنوان بريدك الإلكتروني هنا',
    //   },
    //   facebook: 'فيسبوك',
    //   google: 'جوجل',
    //   signin: 'تسجيل الدخول',
    //   signup: ' إنشاء حساب',
    //   signout: 'تسجيل الخروج',
    //   alreadyHaveAnAccount: ' هل لديك حساب؟ تسجيل الدخول',
    //   signinWithAnotherAccount:
    //     'تسجيل الدخول بإستخدام حساب آخر',
    //   orSigninWith: 'تسجيل الدخول بإستخدام',
    //   emailUnverified: {
    //     title: 'تأكيد عنوان البريد الإلكتروني',
    //     message: `يرجي تأكيد بريدك الإلكتروني <strong>{0}</strong> للمتابعة`,
    //     submit: `إعادة إرسال بريد إلكتروني للتحقق`,
    //     verify: 'التحقق من حسابك',
    //   },
    //   emptyPermissions: {
    //     title: 'إذن الحساب',
    //     message: ` ليس لديك أذونات حتي الآن  انتظر المسؤول لمنحك الامتيازات`,
    //   },
    //   passwordResetEmail: {
    //     message: 'إرسال',
    //     // message:
    //     //   'إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور',
    //     error: `البريد الإلكتروني غير معروف`,
    //   },
    //   passwordReset: {
    //     message: 'إعادة تعيين كلمة المرور',
    //   },
    //   emailAddressVerificationEmail: {
    //     error: `البريد الإلكتروني غير معروف`,
    //   },
    //   verificationEmailSuccess: `تم إرسال رسالة التحقق بنجاح`,
    //   passwordResetEmailSuccess: `تم إرسال بريد إلكتروني لإعادة كلمة المرور بنجاح`,
    //   passwordResetSuccess: `تم تغيير كلمة المرور بنجاح`,
    //   verifyEmail: {
    //     success: 'تم التحقق من البريد الإلكتروني بنجاح',
    //     message:
    //       '   لحظة فقط ، يتم التحقق من بريدك الإلكتروني',
    //   },
    // },
  
    roles: {
      owner: {
        label: 'مالك',
        description: 'Full access to all resources',
      },
      admin: {
        label: 'مشرف / مدير',
        description: 'الوصول الكامل إلى كافة الموارد بإستثناء الإعلانات',
      },
      editor: {
        label: 'Editor',
        description: 'Edit access to all resources',
      },
      viewer: {
        label: 'Viewer',
        description: 'View access to all resources',
      },
      auditLogViewer: {
        label: 'Audit Log Viewer',
        description: 'Access to view audit logs',
      },
      iamSecurityReviewer: {
        label: 'Security Reviewer',
        description: `Full access to manage users roles`,
      },
      entityEditor: {
        label: 'Entity Editor',
        description: 'Edit access to all entities',
      },
      entityViewer: {
        label: 'Entity Viewer',
        description: 'View access to all entities',
      },
      personEditor: {
        label: 'Person Editor',
        description: 'Edit access to People',
      },
      personViewer: {
        label: 'Person Viewer',
        description: 'View access to People',
      },
      busEditor: {
        label: 'Bus Editor',
        description: 'Edit access to Buses',
      },
      busViewer: {
        label: 'Bus Viewer',
        description: 'View access to Buses',
      },
      stationEditor: {
        label: 'Station Editor',
        description: 'Edit access to Stations',
      },
      stationViewer: {
        label: 'Station Viewer',
        description: 'View access to Stations',
      },
      timingEditor: {
        label: 'Timing Editor',
        description: 'Edit access to Timings',
      },
      timingViewer: {
        label: 'Timing Viewer',
        description: 'View access to Timings',
      },
      lineEditor: {
        label: 'Line Editor',
        description: 'Edit access to Lines',
      },
      lineViewer: {
        label: 'Line Viewer',
        description: 'View access to Lines',
      },
      busTripEditor: {
        label: 'BusTrip Editor',
        description: 'Edit access to BusTrip',
      },
      busTripViewer: {
        label: 'BusTrip Viewer',
        description: 'View access to BusTrip',
      },
      clientTripEditor: {
        label: 'clientTripEditor',
        description: 'Edit access to Trip Transaction',
      },
      clientTripViewer: {
        label: 'clientTrip Viewer',
        description: 'View access to Trip Transaction',
      },
      busHistoryEditor: {
        label: 'busHistory Editor',
        description: 'Edit access to bus History',
      },
      busHistoryViewer: {
        label: 'busHistory Viewer',
        description: 'View access to bus History',
      },
      advertismentEditor: {
        label: 'advertisment Editor',
        description: 'Edit access to advertisment',
      },
      advertismentViewer: {
        label: 'advertisment Viewer',
        description: 'View access to advertisment',
      },
      promoCodeEditor: {
        label: 'promoCode Editor',
        description: 'Edit access to promoCode',
      },
      promoCodeViewer: {
        label: 'promoCode Viewer',
        description: 'View access to promoCode',
      },
      rewardingEditor: {
        label: 'rewarding Editor',
        description: 'Edit access to rewarding',
      },
      rewardingViewer: {
        label: 'rewarding Viewer',
        description: 'View access to rewarding',
      },
      timelineEditor: {
        label: 'timeline Editor',
        description: 'Edit access to timeline',
      },
      timelineViewer: {
        label: 'timeline Viewer',
        description: 'View access to timeline',
      },
      tripEditor: {
        label: 'trip Editor',
        description: 'Edit access to trip',
      },
      tripViewer: {
        label: 'trip Viewer',
        description: 'View access to trip',
      },
    },
  
    iam: {
      title: 'المسؤولين',
      menu: 'المسؤولين',
      disable: 'تعطيل',
      disabled: 'Disabled',
      enable: 'تفعيل',
      enabled: 'Enabled',
      doEnableSuccess: 'تم تمكين المستخدم بنجاح',
      doDisableSuccess: 'تم تعطيل المستخدم بنجاح',
      doDisableAllSuccess: 'تم تعطيل المستخدمين بنجاح',
      doEnableAllSuccess:
        'تم تمكين المستخدم (المستخدمين) بنجاح',
      doAddSuccess: 'تم حفظ المستخدم (المستخدمين) بنجاح',
      doUpdateSuccess: 'تم حفظ المستخدم بنجاح',
      viewBy: 'عرض بواسطة',
      users: {
        name: 'users',
        label: 'Users',
        exporterFileName: 'users_export',
        doRemoveAllSelectedSuccess:
          'تمت إزالة الأذونات بنجاح',
      },
      roles: {
        label: 'الصلاحيات',
        doRemoveAllSelectedSuccess:
          'تمت إزالة الأذونات بنجاح',
      },
      edit: {
        title: 'تعديل',
      },
      new: {
        title: 'إضافة مسئول',
        emailsHint:
          'افصل بين عناوين البريد الإلكتروني المتعددة باستخدام الحرف الفاصلة ',
      },
      view: {
        title: 'عرض ',
        activity: 'النشاط',
      },
      importer: {
        title: 'إستيراد',
        fileName: 'users_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space  Relationships must be the الرقم التعريفى of the referenced records separated by space  Roles must be the role ids separated by space ',
      },
      errors: {
        userAlreadyExists:
          'المستخدم مع هذا البريد الإلكتروني موجود بالفعل',
        userNotFound: 'المستخدم غير موجود',
        disablingHimself: `لا يمكنك تعطيل نفسك`,
        revokingOwnPermission: ` لا يمكنك إلغاء إذن المالك الخاص بك`,
      },
    },
  
    user: {
      fields: {
        id: 'الرقم التعريفى',
        authenticationUid: 'Authentication Uid',
        avatars: 'الصورة الشخصية',
        email: 'البريد الإلكتروني',
        emails: 'البريد الإلكتروني',
        fullName: 'الإسم كاملاً',
        firstName: 'الإسم الأول',
        lastName: 'الإسم الأخير',
        status: 'الحالة',
        disabled: 'حالة الحساب',
        phoneNumber: 'رقم الهاتف',
        role: 'الصلاحية',
        createdAt: 'إنشاء في',
        updatedAt: 'تعديل في',
        roleUser: 'Role/User',
        roles: 'الصلاحيات',
        createdAtRange: 'إنشاء في',
        password: 'كلمة المرور',
        rememberMe: 'تذكرني',
      },
      enabled: 'تفعيل',
      disabled: 'تعطيل',
      validations: {
        // eslint-disable-next-line
        email: 'البريد الإلكتروني ${value} غير صالح',
      },
    },
  
    auditLog: {
      menu: 'Audit Logs',
      title: 'Audit Logs',
      exporterFileName: 'audit_log_export',
      entityNamesHint:
        'Separate multiple entities using the comma character ',
      fields: {
        id: 'الرقم التعريفى',
        timestampRange: 'Period',
        entityName: 'Entity',
        entityNames: 'Entities',
        entityId: 'Entity الرقم التعريفى',
        action: 'Action',
        values: 'Values',
        timestamp: 'Date',
        createdByEmail: 'User Email',
      },
    },
  
    settings: {
      title: 'Settings',
      menu: 'Settings',
      save: {
        success:
          'Settings saved successfully  The page will reload in {0} seconds for changes to take effect ',
      },
      fields: {
        theme: 'Theme',
      },
      colors: {
        default: 'Default',
        cyan: 'Cyan',
        'geek-blue': 'Geek Blue',
        gold: 'Gold',
        lime: 'Lime',
        magenta: 'Magenta',
        orange: 'Orange',
        'polar-green': 'Polar Green',
        purple: 'Purple',
        red: 'Red',
        volcano: 'Volcano',
        yellow: 'Yellow',
      },
    },
  
    home: {
      menu: 'الرئيسية',
      message: `This page uses fake data for demonstration purposes only  You can edit it at frontend/src/modules/home/components/home-page vue `,
      charts: {
        day: 'Day',
        red: 'Red',
        green: 'Green',
        yellow: 'Yellow',
        grey: 'Grey',
        blue: 'Blue',
        orange: 'Orange',
        months: {
          1: 'January',
          2: 'February',
          3: 'March',
          4: 'April',
          5: 'May',
          6: 'June',
          7: 'July',
        },
        eating: 'Eating',
        drinking: 'Drinking',
        sleeping: 'Sleeping',
        designing: 'Designing',
        coding: 'Coding',
        cycling: 'Cycling',
        running: 'Running',
        customer: 'Customer',
      },
    },
  
    errors: {
      emailRequired: 'البريد الإلكتروني مطلوب',
      passwordRequired: 'كلمة المرور مطلوبة',
      invalidEmail: 'بريد إلكتروني غير صالح',
  
      rolesRequired: 'الصلاحية مطلوبة',
      vehicleTypeRequired: 'نوع المركبة مطلوب',
      vehicleNumberRequired: 'رقم المركبة مطلوب',
      vehicleNumberIsInteger:
        'رقم المركبة يجب ان يكون عددً صحيحاً',
      SeatsNumberRequired: 'عدد المقاعد مطلوب',
      SeatsNumberIsInteger:
        'عدد المقاعد يجب ان يكون عددً صحيحاً',
      vehiclePlateRequired: 'لوحة المركبة مطلوبة',
      threeEntriesMustBeText: 'يجب ان يكون اول ٣ مدخلات حروف',
      phoneRequired: 'رقم الجوال مطلوب',
      regionRequired: 'المنطقة مطلوبة',
      busRequired: 'الحافلة مطلوبة',
      nameRequired: 'المسمي مطلوب',
      numOfPointsRequired: 'عدد النقاط مطلوب',
      numOfTripsRequired: 'عدد الرحلات مطلوب',
      percentageOfTripsRequired:
        'نسبة الخصم على الرحلة مطلوب',
      maxNumIs_1: 'يرجى استخدام أقصى عدد هو 1',
      expirationDateRequired: 'تاريخ إنتهاء الصلاحية مطلوب',
      lngRequired: 'خط الطول مطلوب',
      latRequired: 'خط العرض مطلوب',
      lngMustBeNum: 'يجب أن يكون خط الطول رقماً فقط',
      latMustBeNum: 'يجب أن يكون خط العرض رقماً فقط',
      startDateRequired: 'تاريخ البداية مطلوب',
      endDateRequired: 'تاريخ الإنتهاء مطلوب',
      publishDateRequired: 'تاريخ النشر مطلوب',
  
      backToHome: ' العودة للصفحة الرئيسية',
      403: `عذرًا ، لا يمكنك الوصول إلي هذه الصفحة`,
      404: 'عفوًا ، الصفحة التي قمت بزيارتها غير موجودة',
      500: 'عذرًا ، يبلغ الخادم عن خطأ',
      forbidden: {
        message: 'ممنوع',
      },
      validation: {
        message: 'حدث خطأ',
      },
      defaultErrorMessage: 'عفوًا ، حدث خطأ',
    },
  
    // See https://github com/jquense/yup#using-a-custom-locale-dictionary
    /* eslint-disable */
    validation: {
      mixed: {
        default: '${path} is invalid',
        required: '${path} is required',
        oneOf:
          '${path} must be one of the following values: ${values}',
        notOneOf:
          '${path} must not be one of the following values: ${values}',
        notType: ({ path, type, value, originalValue }) => {
          return `${path} must be a ${type}`;
        },
      },
      string: {
        length:
          '${path} must be exactly ${length} characters',
        min: '${path} must be at least ${min} characters',
        max: '${path} must be at most ${max} characters',
        matches:
          '${path} must match the following: "${regex}"',
        email:
          'يجب أن يكون $ {path} بريدًا إلكترونيًا صالحًا',
        url: '${path} must be a valid URL',
        trim: '${path} must be a trimmed string',
        lowercase: '${path} must be a lowercase string',
        uppercase: '${path} must be a upper case string',
        selected: '${path} must be selected',
      },
      number: {
        min:
          '${path} must be greater than or equal to ${min}',
        max: '${path} must be less than or equal to ${max}',
        lessThan: '${path} must be less than ${less}',
        moreThan: '${path} must be greater than ${more}',
        notEqual: '${path} must be not equal to ${notEqual}',
        positive: '${path} must be a positive number',
        negative: '${path} must be a negative number',
        integer: '${path} must be an integer',
        invalid: '${path} must be a number',
      },
      date: {
        min: '${path} field must be later than ${min}',
        max: '${path} field must be at earlier than ${max}',
      },
      boolean: {},
      object: {
        noUnknown:
          '${path} field cannot have keys not specified in the object shape',
      },
      array: {
        min: '${path} field must have at least ${min} items',
        max:
          '${path} field must have less than or equal to ${max} items',
      },
    },
  
    /* eslint-disable */
    fileUploader: {
      upload: 'رفع',
      image: 'يجب عليك تحميل صورة',
      size: 'الملف كبير جدا  الحجم الأقصي المسموح به هو {0}',
      formats: `يجب أن يكون التنسيق غير صالح '{0}' `,
    },
  
    importer: {
      line: 'Line',
      status: 'Status',
      pending: 'Pending',
      imported: 'Imported',
      error: 'Error',
      total: `{0} imported, {1} pending and {2} with error`,
      importedMessage: `Processed {0} of {1} `,
      noNavigateAwayMessage:
        'Do not navigate away from this page or import will be stopped ',
      completed: {
        success:
          'Import completed  All rows were successfully imported ',
        someErrors:
          'Processing completed, but some rows were unable to be imported ',
        allErrors: 'Import failed  There are no valid rows ',
      },
      form: {
        downloadTemplate: 'Download the template',
        hint:
          'Click or drag the file to this area to continue',
      },
      list: {
        discardConfirm:
          'Are you sure? Non-imported data will be lost ',
      },
      errors: {
        invalidFileEmpty: 'The file is empty',
        invalidFileExcel:
          'Only excel ( xlsx) files are allowed',
        invalidFileUpload:
          'Invalid file  Make sure you are using the last version of the template ',
        importHashRequired: 'Import hash is required',
        importHashExistent: 'Data has already been imported',
      },
    },
  
    autocomplete: {
      loading: 'تحميل٫٫٫',
    },
  
    imagesViewer: {
      noImage: 'لا يوجد صورة',
    },
  
    firebaseErrors: {
      'auth/user-disabled': 'حسابك مغلق',
      'auth/user-not-found': `آسف ، نحن لا نتعرف علي بيانات الاعتماد الخاصة بك`,
      'auth/wrong-password': `آسف ، نحن لا نتعرف علي بيانات الاعتماد الخاصة بك`,
      'auth/weak-password': 'كلمة المرور هذه ضعيفة جدًا',
      'auth/email-already-in-use':
        'البريد الالكتروني قيد الاستخدام بالفعل',
      'auth/invalid-email':
        'يرجي تقديم عنوان بريد إلكتروني صالح',
      'auth/account-exists-with-different-credential':
        'البريد الإلكتروني مستخدم بالفعل لطريقة مصادقة مختلفة ',
      'auth/credential-already-in-use':
        'بيانات الاعتماد قيد الاستخدام بالفعل',
    },
  };
  
  export default ar;
  