import Vue from 'vue';

import './styles/quasar.scss';
import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/dist/quasar.addon.css';
import { Quasar, Meta, LocalStorage,Loading } from 'quasar';
// import 'vuetify-avatar-uploader';


Vue.use(Quasar, Meta, {
  config: {
    Loading,
    loadingBar: { 
      color: 'indigo-7' // --> from Quasar Color Palette 
 }
   },
  /* not needed if importStrategy is not 'manual' */

  components: ['QImg', 'VAvatarUploader'],
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  framework: {
    cssAddon: true,
    lang: 'ar',
    config:{
      screen: {
        bodyClasses: true // <<< add this
      }
    },
    screen: {
      bodyClasses: true // <<< add this
    },
    plugins: [
      'Notify'
    ],
    // plugins: [
    //   LocalStorage
    // ]
    // animations: 'all'

    // plugins: [
    //   LocalStorage
    // ]
  },
  build: {
    rtl: true,
  },
  plugins: [
    // eslint-disable-next-line no-undef
    LocalStorage,
    Loading
  ],
});
