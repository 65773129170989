//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getLanguages } from '@/i18n';
import i18n from '@/vueI18n'
import { Quasar } from 'quasar'

export default {
  name: 'app-i18n-select',

  data() {
    return {
      ar:false,
      value: i18n.locale,
    };
  },

  computed: {
    // languages() {
    //   return getLanguages();
    // },
     languages() {
      const languages = getLanguages().map((lang) => {
        let obj = {
          key: lang.id,
          label: lang.label,
          value: lang.id,
        };
        return obj;
      });
      return languages;
    },
  },

  methods: {
    doChangeLanguage(language) {
      console.log(language);
            i18n.locale = language;
      localStorage.setItem('language', language);
      if (language === 'ar') {
        this.ar=true;
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      } else {
        this.ar=false;
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }

      const { lang } = this.$route.params;
      let route = '';
      if (lang && (lang === 'ar' || lang === 'en')) {
        route = `/${language}${this.$route.fullPath.substring(
          3,
        )}`;
      } else {
        route = `/${language}${this.$route.fullPath}`;
      }
      this.$router.push(route);
    },
  },
  mounted(){
    // console.log("mounted inlangiage", this.value)
    if(this.value === "ar"){
      this.ar = true
    }
    else{
      this.ar = false
    }
  }
};
