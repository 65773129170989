import { i18n } from "@/vueI18n";
import ProjectViewPage from "@/modules/product/components/product-view-page.vue";
import ProjectListPage from "@/modules/product/components/product-list-page.vue";
const LayoutPage = () => import("@/modules/layout/components/layout.vue");

export default [
  {
    path: "",
    meta: { unauth: true },
    component: LayoutPage,
    children: [
      {
        name: "product/:id",
        path: "/:lang?/products/:id",
        component: ProjectViewPage,
        meta: { unauth: true },
        beforeEnter: (to, from, next) => {
          document.title = i18n("app.title") + " | " + i18n("app.products");
          next();
        },
      },
      {
        name: "product",
        path: "/:lang?/products",
        component: ProjectListPage,
        meta: { unauth: true },
        beforeEnter: (to, from, next) => {
          document.title = i18n("app.title") + " | " + i18n("app.products");
          next();
        },
      },
    ],
  },
];
