//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["src", "alt", "class"],
};
