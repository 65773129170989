//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper from "swiper";

import "swiper/swiper-bundle.min.css";
import i18n from "@/vueI18n";
import ProductData from "@/shared/json/product-data.json";
import ProductDataAr from "@/shared/json/product-data-ar.json";
import productContactForm from "./product-contact-form.vue";

import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
export default {
  data() {
    return {
      activeIndex: 0,
      thumbs: null,
      mainSlider: null,
    };
  },
  components: { productContactForm },

  mounted() {
    this.scrollTop();

    this.thumbs = new Swiper(".smallSwiper", {
      direction: window.innerWidth > 500 ? "vertical" : "horizontal",
      slidesPerView: 4,
      spaceBetween: window.innerWidth > 500 ? 20 : 10,
      // Navigation arrows
    });

    this.mainSlider = new Swiper(".swiper", {
      gap: 20,
      // And if we need scrollbar
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },

  computed: {
    language() {
      return i18n.locale;
    },
    items() {
      if (this.language == "en") return ProductData.products;
      return ProductDataAr.products;
    },
    item() {
      var newArray = this.items.filter((el) => {
        return el.id == this.$route.params.id;
      });
      return newArray[0];
    },
  },
  watch: {
    language() {
      document.title =
        this.i18n("app.title") + " | " + this.i18n("app.products");
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    scrollTop() {
      var offset = 0;
      const ele = document.getElementById("header");
      var target = getScrollTarget(ele);
      var duration = 0;
      this.drawer = false;
      setScrollPosition(target, offset, duration);
    },

    handleThumbsChanges(index) {
      this.mainSlider.slideTo(index);
    },

    handleMainChanges(index) {
      this.thumbs.slideTo(index);
    },
  },
};
