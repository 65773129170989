const LayoutPage = () => import("@/modules/layout/components/layout.vue");
const HomePage = () => import("@/modules/home/components/home-page.vue");
import language , {i18n} from "@/vueI18n";
export default [
  {
    path: "/:lang?",
    meta: { unauth: true },
    exact: true,
    component: LayoutPage,
    children: [
      {
        name: "home",
        path: "",
        component: HomePage,
        meta: { unauth: true },
        beforeEnter: (to, from, next) => {
          language.locale=to.params.lang
          document.title = i18n("app.title") + " | " + i18n("app.home");
          next();
        },
      },
    ],
  },
];
