//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from "@/vueI18n";
export default {
  computed: {
    language() {
      return i18n.locale;
    },
  },
  methods: {
    goToProducts() {
      this.$router.push(`/${this.language}/products`);
    },
    viewProduct(id) {
      this.$router.push(`/${this.language}/products/${id}`);
    },
  },
  props: ["thumbnail", "description", "title", "id"],
};
