const firebaseConfig = {
  apiKey: "AIzaSyBvsxq7H5CQ2QbOjWhb8DONMGJ_GmYRgpY",
  authDomain: "esli-egypt.firebaseapp.com",
  projectId: "esli-egypt",
  storageBucket: "esli-egypt.appspot.com",
  messagingSenderId: "1096301780840",
  appId: "1:1096301780840:web:045a4b9a63701f28d000dd",
  measurementId: "G-4NKEKF5VVQ"
};
// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/api`;

// const backendUrl = `https://europe-west3-${
//   firebaseConfig.projectId
// }.cloudfunctions.net/api`;


// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
};
